/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "Roboto";
    font-style: italic;
    src: url(./fonts/Roboto-Italic.ttf) format("truetype");
  }
  @font-face {
    font-family: "PlayfairDisplay";
    font-weight: 400;
    src: url(./fonts/PlayfairDisplay-Regular.ttf) format("truetype");
  }
  @font-face {
    font-family: "PlayfairDisplay";
    font-weight: 700;
    src: url(./fonts/PlayfairDisplay-Bold.ttf) format("truetype");
  }
  @font-face {
    font-family: "PlayfairDisplay";
    font-style: italic;
    src: url(./fonts/PlayfairDisplay-Italic.ttf) format("truetype");
  }
}
